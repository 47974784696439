<template>
  <div class="addreport">
    <div class="addreportTop">
      <div class="steps">
        <div :class="{ active: active == 0 }">
          <span class="stepsNum">1</span> 新建成绩单
        </div>
        <div class="line"></div>
        <div :class="{ active: active == 2 }">
          <span class="stepsNum">2</span> 预览并发送
        </div>
      </div>
    </div>
    <div class="addreportContent">
      <div class="contentBox">
        <div class="contents">
          <div class="contents1" v-if="active == 0">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane name="1">
                <div slot="label">
                  <el-radio v-model="activeName" label="1">录入学生成绩</el-radio>
                  <p class="prompt">（请确认已经录入班级学生信息）</p>
                </div>
                <el-form
                  :inline="true"
                  ref="StudentsClassForm1"
                  :model="StudentsClass"
                  class="demo-form-inline"
                  :rules="StudentsClassRules1"
                  key="StudentsClassForm1"
                >
                  <el-form-item prop="grade">
                    <div slot="label">
                      <span class="fl">年</span>
                      <span class="fr">级:</span>
                    </div>
                    <el-select v-model="StudentsClass.grade" placeholder="选择年级">
                      <el-option
                        v-for="(item, index) in options.Grade"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="班级" prop="classes">
                    <div slot="label">
                      <span class="fl">班</span>
                      <span class="fr">级:</span>
                    </div>
                    <el-select v-model="StudentsClass.classes" placeholder="选择班级">
                      <el-option
                        v-for="(item, index) in options.classes"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="科目" prop="course">
                    <div slot="label">
                      <span class="fl">科</span>
                      <span class="fr">目:</span>
                    </div>
                    <el-select v-model="StudentsClass.course" placeholder="选择科目">
                      <el-option
                        v-for="(item, index) in options.Course"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="考试类型:" prop="examType">
                    <el-select v-model="StudentsClass.examType" placeholder="考试类型">
                      <el-option
                        v-for="(item, index) in options.examType"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="考试日期:" prop="examDate">
                    <el-date-picker
                      v-model="StudentsClass.examDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="考试日期"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="总分" prop="fullMarks">
                    <div slot="label">
                      <span class="fl">总</span>
                      <span class="fr">分:</span>
                    </div>
                    <el-input type="number" v-model="StudentsClass.fullMarks" />
                  </el-form-item>
                  <el-form-item label="补充标题:" prop="title">
                    <el-input maxlength="10" v-model="StudentsClass.title" />
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane name="2">
                <div slot="label">
                  <el-radio v-model="activeName" label="2">导入成绩单</el-radio>
                </div>
                <el-form
                  :inline="true"
                  :model="StudentsClass"
                  ref="StudentsClassForm2"
                  class="demo-form-inline importResults"
                  :rules="StudentsClassRules2"
                  key="StudentsClassForm2"
                >
                  <el-form-item label="年级" prop="grade">
                    <div slot="label">
                      <span class="fl">年</span>
                      <span class="fr">级:</span>
                    </div>
                    <el-select v-model="StudentsClass.grade" placeholder="选择年级">
                      <el-option
                        v-for="(item, index) in options.Grade"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="班级" prop="classes">
                    <div slot="label">
                      <span class="fl">班</span>
                      <span class="fr">级:</span>
                    </div>
                    <el-select v-model="StudentsClass.classes" placeholder="选择班级">
                      <el-option
                        v-for="(item, index) in options.classes"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="科目" prop="course">
                    <div slot="label">
                      <span class="fl">科</span>
                      <span class="fr">目:</span>
                    </div>
                    <el-select v-model="StudentsClass.course" placeholder="选择科目">
                      <el-option
                        v-for="(item, index) in options.Course"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="考试类型:" prop="examType">
                    <el-select v-model="StudentsClass.examType" placeholder="考试类型">
                      <el-option
                        v-for="(item, index) in options.examType"
                        :key="index"
                        :label="item.text"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="考试日期:" prop="examDate">
                    <el-date-picker
                      v-model="StudentsClass.examDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="考试日期"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="总分" prop="fullMarks">
                    <div slot="label">
                      <span class="fl">总</span>
                      <span class="fr">分:</span>
                    </div>
                    <el-input type="number" v-model="StudentsClass.fullMarks" />
                  </el-form-item>
                  <el-form-item label="补充标题:" prop="title">
                    <el-input maxlength="10" v-model="StudentsClass.title" />
                  </el-form-item>
                </el-form>
                <el-upload
                  class="upload-demo"
                  accept=".xlsx"
                  :on-success="upSuccess"
                  :on-error="uperror"
                  :action="
                    'https://api.joyousoa.com/school-report/report/upload?grade=' +
                    encodeURI(StudentsClass.grade) +
                    '&classes=' +
                    encodeURI(StudentsClass.classes) +
                    '&course=' +
                    encodeURI(StudentsClass.course) +
                    '&examDate=' +
                    encodeURI(StudentsClass.examDate) +
                    '&fullMarks=' +
                    encodeURI(StudentsClass.fullMarks) +
                    '&title=' +
                    encodeURI(StudentsClass.title) +
                    '&examType=' +
                    encodeURI(StudentsClass.examType) +
                    '&reportId=' +
                    encodeURI(StudentsClass.reportId)
                  "
                  :headers="uploadHeaders"
                  multiple
                  :limit="1"
                  :disabled="uploadIsDisabled"
                  :auto-upload="false"
                  ref="upload"
                  :file-list="fileList"
                  :on-remove="handleRemove"
                  :on-exceed="exceed"
                  :on-change="changeXlsx"
                >
                  <el-button class="importBut" @click="importButClick"
                    >导入成绩单</el-button
                  >
                  <p class="getTemplate" slot="tip">
                    <a href="./league.xlsx" downloa="成绩单模板.xlsx">下载模板</a>
                  </p>
                </el-upload>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="contents2" v-if="active == 1">
            <p class="tableName">
              <i class="el-icon-caret-right"> </i> {{ addReportTitle }}
            </p>
            <el-tabs v-model="tableTabs2" @tab-click="tabChange2">
              <el-tab-pane name="1">
                <div slot="label">
                  <el-radio v-model="tableTabs2" label="1">按姓名查看</el-radio>
                </div>
                <results-table
                  key="resultstable1"
                  :type="false"
                  :height="'380'"
                  :data="reportDef"
                  @upEntryForm="upEntryForm"
                ></results-table>
              </el-tab-pane>
              <el-tab-pane name="2">
                <div slot="label">
                  <el-radio v-model="tableTabs2" label="2">按学号查看</el-radio>
                </div>
                <results-table
                  key="resultstable2"
                  :type="false"
                  :height="'380'"
                  :data="reportDef"
                  @upEntryForm="upEntryForm"
                ></results-table>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="contents3" v-if="active == 2">
            <p class="tableName">
              <i class="el-icon-caret-right"></i>
              {{ addReportTitle }}
            </p>
            <el-tabs v-model="tableTabs" @tab-click="tabChange">
              <el-tab-pane name="1">
                <div slot="label">
                  <el-radio v-model="tableTabs" label="1">按姓名查看</el-radio>
                </div>
                <results-table
                  key="resultstable3"
                  :type="true"
                  :data="Reports"
                ></results-table>
              </el-tab-pane>
              <el-tab-pane name="2">
                <div slot="label">
                  <el-radio v-model="tableTabs" label="2">按名次查看</el-radio>
                </div>
                <results-table
                  key="resultstable4"
                  :type="true"
                  :data="Reports"
                ></results-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="contentBot1 bot" v-if="active == 0">
          <el-button style="margin-top: 12px" @click="go1">下一步</el-button>
        </div>
        <div class="contentBot2 bot" v-else-if="active == 1">
          <el-button
            style="margin-top: 12px"
            @click.prevent="previous"
            v-if="previousFlag"
            >上一步</el-button
          >
          <el-button style="margin-top: 12px" @click.prevent="save" class="saveButton"
            >保存到草稿箱</el-button
          >
          <el-button style="margin-top: 12px" @click.prevent="entrySend"
            >下一步</el-button
          >
        </div>
        <div class="contentBot2 bot" v-else-if="active == 2">
          <el-button style="margin-top: 12px" @click.prevent="previous">上一步</el-button>
          <el-button style="margin-top: 12px" @click="seng">发送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/http/api";
import resultsTable from "@/components/resultsTable";
function compare(p) {
  //这是比较函数
  return function (m, n) {
    var a = m[p];
    var b = n[p];
    return a.localeCompare(b, "zh-CN", { numeric: true });
  };
}

export default {
  name: "addreport",
  props: {},
  components: { resultsTable },
  data() {
    return {
      active: 0,
      activeName: "1",
      StudentsClass: {
        examDate: "",
        grade: "",
        classes: "",
        course: "",
        examType: "",
        title: "",
        fullMarks: "",
        details: [],
        reportId: "",
        id: "",
      },
      // 班年级选项信息
      options: {
        Grade: [],
        Course: [],
        classes: [],
        examType: [],
      },
      tableTabs: "2",
      tableTabs2: "2",
      resultTableName: "",
      // 成绩单列表
      Reports: [],
      // 学生成绩信息表
      report: [],
      reportDef: [],
      // 成绩单数据id
      resultID: "",
      fileList: [],
      // 上传学生列表请求头设置
      uploadHeaders: {
        Authorization: "Bearer" + " " + window.localStorage.getItem("Authorization"),
      },
      // 第一步完成状态
      next0: false,
      // 手动录入状态
      entrySendType: false,
      // 录入学生班级信息正则
      StudentsClassRules1: {
        grade: [{ required: true, message: "请选择", trigger: "blur" }],
        classes: [{ required: true, message: "请选择", trigger: "blur" }],
        course: [{ required: true, message: "请选择", trigger: "blur" }],
        examType: [{ required: true, message: "请选择", trigger: "blur" }],
        examDate: [{ required: true, message: "请选择", trigger: "blur" }],
        fullMarks: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      StudentsClassRules2: {
        grade: [{ required: true, message: "请选择", trigger: "blur" }],
        classes: [{ required: true, message: "请选择", trigger: "blur" }],
        course: [{ required: true, message: "请选择", trigger: "blur" }],
        examType: [{ required: true, message: "请选择", trigger: "blur" }],
        examDate: [{ required: true, message: "请选择", trigger: "blur" }],
        fullMarks: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      userInfo: {
        id: "",
        mobile: "",
        orgId: "",
        orgName: "",
        orgType: "",
        realName: "",
        username: "",
      },
      sort: "",
      previousFlag: true,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.previousFlag = false;
      // this.resultID = this.$route.params.id;
      // 获取成绩列表
      this.getReportByIDforchange(this.$route.params.id);
      this.active = 1;
    }

    // 获取用户信息
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    // 获取用户信息
    async getUserInfo() {
      const data = await http.getUserInfo();
      if (data.status == 200) {
        this.userInfo = data.data;
        // 获取字典
        this.getOptions();
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    //下一步
    next() {
      if (this.activeName == 2 && this.next0) {
        this.active += 2;
      } else if (
        this.activeName == 1 &&
        this.StudentsClass.examDate &&
        this.StudentsClass.grade &&
        this.StudentsClass.classes &&
        this.StudentsClass.course &&
        this.StudentsClass.examType &&
        this.StudentsClass.fullMarks
      ) {
        this.active++;
      }

      if (this.active == 2) {
        // 获取成绩列表
        this.getReportByID();
      }
    },
    //  上一步
    previous() {
      if (this.activeName == 2) {
        this.active -= 2;
      } else {
        this.active--;
      }
    },
    // 发送
    async seng() {
      const { data: data } = await http.sengReportByID({
        reportId: this.resultID,
      });
      if (data.code == 200) {
        this.$router.push({
          name: "sendRecord",
          params: {
            id: this.resultID,
          },
        });
      } else {
        this.$message.error({
          message: data.msg || "发送失败",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 录入学生成绩
    go1() {
      this.$refs[`StudentsClassForm${this.activeName}`].validate(async (valid) => {
        if (valid) {
          if (this.activeName == 1) {
            const { data: data } = await http.getAllStudentList(
              this.StudentsClass.grade,
              this.StudentsClass.classes
            );
            if (data && data.length) {
              // data.forEach((item) => {
              //   if (item.sno) {
              //     item.sno = +item.sno;
              //   }
              // });
              this.report = data;
              let arr = Object.assign([], this.report);
              this.reportDef = arr.sort(compare("sno"));
              this.next();
            } else {
              this.$message({
                message: "无学生数据,请先去学生管理页面添加学生信息",
                type: "error",
                duration: 3000,
              });
            }
          } else {
            // console.log(this.fileList);
            // if (!this.fileList.length)
            //   return this.$message.error({
            //     message: "请上传文件",
            //     type: "error",
            //     duration: 3000,
            //   });
            if (!this.fileList.length)
              return this.$message({
                message: "请导入表格文件",
                type: "error",
                duration: 3000,
              });
            this.$refs.upload.submit();
          }
        } else {
          if (this.activeName == 1) {
            this.$message({
              message: "请输入学生相关信息",
              type: "error",
              duration: 3000,
            });
          } else {
            this.$message({
              message: "请输入学生相关信息并导入表格",
              type: "error",
              duration: 3000,
            });
          }

          return false;
        }
      });
    },
    // 修改草稿箱
    async getReportByIDforchange(id) {
      const { data: data } = await http.getReportByID(id, {
        sort: this.sort,
      });

      if (data.details.length) {
        data.details.forEach((val) => {
          if (!val.score) {
            val.score = 0;
          }
          if (val.score && val.score < 0) {
            val.score = 0;
          }
        });
        this.report = data.details;
        let arr = Object.assign([], this.report);

        this.reportDef = arr.sort(compare("sno"));
        this.resultTableName = data.title;
        this.StudentsClass = data;
      } else {
        return this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    // 子组件传出的录入成绩信息
    upEntryForm(val) {
      this.StudentsClass.details = val;
    },
    // 保存成绩单
    async save() {
      let flag = false;
      this.StudentsClass.details.forEach((val) => {
        if (!val.score && val.score !== 0) flag = true;
        if (val.score === "" || +val.score < 0) {
          flag = true;
        }
      });
      if (flag)
        return this.$message.error({
          message: "请输入正确的成绩",
          type: "error",
          duration: 3000,
        });
      if (this.$route.params.id) {
        const { data: data } = await http.changeReport(this.StudentsClass);
        if (data.code == 200) {
          if (data.data) {
            this.$message.error({
              message: "录入成功",
              type: "error",
              duration: 3000,
            });
            this.resultID = data.id;
            this.StudentsClass.id = data.id;
            this.StudentsClass.reportId = data.id;
            this.entrySendType = true;
            this.$router.push("drafts");
          }
        } else {
          this.$message.error({
            message: data.msg,
            type: "error",
            duration: 3000,
          });
        }
      } else {
        const { data: data } = await http.addReport(this.StudentsClass);
        if (data.code == 200) {
          if (data.data) {
            this.$message.error({
              message: "录入成功",
              type: "error",
              duration: 3000,
            });
            this.resultID = data.id;
            this.StudentsClass.id = data.id;
            this.StudentsClass.reportId = data.id;
            this.entrySendType = true;
            this.$router.push("drafts");
          }
        } else {
          this.$message.error({
            message: data.msg,
            type: "error",
            duration: 3000,
          });
        }
      }
    },
    // 手动发送
    async entrySend() {
      if (this.entrySendType) {
        this.entrySendType = false;
        return this.next();
      }

      let flag = false;

      this.StudentsClass.details.forEach((val) => {
        if (!val.score && val.score !== 0) {
          flag = true;
        }
        if (val.score === "" || +val.score < 0) {
          flag = true;
        }
      });
      if (flag)
        return this.$message.error({
          message: "请输入正确的成绩",
          type: "error",
          duration: 3000,
        });

      const { data: data } = await http.addReport(this.StudentsClass);

      if (data.code == 200) {
        this.resultID = data.data;
        this.StudentsClass.id = data.data;
        this.next();
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    handleClick(tab) {
      this.$refs[`StudentsClassForm${this.activeName}`].resetFields();
    },
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //  上传成功处理
    upSuccess(response, file, fileList) {
      if (response.code == 200) {
        this.$message.error({
          message: "上传成功",
          type: "error",
          duration: 3000,
        });
        this.$refs.upload.clearFiles();
        this.fileList = [];
        this.resultID = response.data;
        this.StudentsClass.reportId = response.data;

        this.next0 = true;
        this.next();
      } else {
        this.$refs.upload.clearFiles();
        this.fileList = [];
        this.$message({
          message: response.msg + "请重新导入",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 上传失败处理
    uperror(err, file, fileList) {
      this.$message({
        message: "上传失败",
        type: "error",
        duration: 3000,
      });
    },
    // 文件改变
    changeXlsx(file, fileList) {
      // console.log(file, fileList, this.fileList);
      this.fileList = fileList;
    },
    // 删除成绩单
    handleRemove(file, fileList) {
      // this.$refs.upload.clearFiles();
      // this.fileList = [];
      this.fileList = fileList;
    },
    // 超出提示
    exceed() {
      this.$message.error({
        message: "一次只能上传一张表格",
        type: "error",
        duration: 3000,
      });
    },
    // 排序切换
    tabChange(e, val) {
      if (this.tableTabs == 1) {
        this.sort = "name";
      } else {
        this.sort = "";
      }
      this.getReportByID();
    },
    tabChange2(e, val) {
      let arr = Object.assign([], this.report);
      if (this.tableTabs2 == 2) {
        this.reportDef = arr.sort(compare("sno"));
      } else {
        this.reportDef = this.report;
      }
    },
    // 根据ID获取成绩单列表
    async getReportByID() {
      try {
        const { data: data } = await http.getReportByID(this.resultID, {
          sort: this.sort,
        });
        // if (data.details.length) {
        //   data.details.forEach((item) => {
        //     if (item.sno) {
        //       item.sno = +item.sno;
        //     }
        //   });
        // }
        this.Reports = data.details;
        this.resultTableName = data.title;
      } catch (error) {
        this.$message.error({
          message: "获取失败",
          type: "error",
          duration: 3000,
        });
      }
    },
    importButClick() {
      if (
        !this.StudentsClass.examDate ||
        !this.StudentsClass.grade ||
        !this.StudentsClass.classes ||
        !this.StudentsClass.course ||
        !this.StudentsClass.examType
      ) {
        return this.$message.error({
          message: "请选择本次录入班年级等相关信息",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取下拉选项
    async getOptions() {
      if (this.userInfo.orgType == "141203") {
        const Grade = await http.getElementaryGrade();
        if (Grade.status == 200) {
          this.options.Grade = Grade.data;
        } else {
          this.$message.error({
            message: Grade.msg,
            type: "error",
            duration: 3000,
          });
        }
        const Course = await http.getElementaryCourse();
        if (Course.status == 200) {
          this.options.Course = Course.data;
        } else {
          this.$message.error({
            message: Course.msg,
            type: "error",
            duration: 3000,
          });
        }
      } else {
        const Grade = await http.getMiddleGrade();
        if (Grade.status == 200) {
          this.options.Grade = Grade.data;
        } else {
          this.$message.error({
            message: Grade.msg,
            type: "error",
            duration: 3000,
          });
        }
        const Course = await http.getMiddleCourse();
        if (Course.status == 200) {
          this.options.Course = Course.data;
        } else {
          this.$message.error({
            message: Course.msg,
            type: "error",
            duration: 3000,
          });
        }
      }
      const classes = await http.getClasses();
      if (classes.status == 200) {
        this.options.classes = classes.data;
      } else {
        this.$message.error({
          message: classes.msg,
          type: "error",
          duration: 3000,
        });
      }
      const examType = await http.getExamType();
      if (examType.status == 200) {
        this.options.examType = examType.data;
      } else {
        this.$message.error({
          message: examType.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
  },
  computed: {
    uploadIsDisabled() {
      if (
        !this.StudentsClass.examDate ||
        !this.StudentsClass.grade ||
        !this.StudentsClass.classes ||
        !this.StudentsClass.course ||
        !this.StudentsClass.examType
      ) {
        return true;
      }

      return false;
    },
    // 录入成绩单标题
    addReportTitle() {
      let school = this.userInfo.orgName;
      let grade = this.StudentsClass.grade.slice(0, 1);
      // if (this.StudentsClass.grade == "一年级") {
      //   grade = "1";
      // } else if (this.StudentsClass.grade == "二年级") {
      //   grade = "2";
      // } else if (this.StudentsClass.grade == "三年级") {
      //   grade = "3";
      // } else if (this.StudentsClass.grade == "四年级") {
      //   grade = "4";
      // } else if (this.StudentsClass.grade == "五年级") {
      //   grade = "5";
      // } else if (this.StudentsClass.grade == "六年级") {
      //   grade = "6";
      // } else if (this.StudentsClass.grade == "初一年级") {
      //   grade = "7";
      // } else if (this.StudentsClass.grade == "初二年级") {
      //   grade = "8";
      // } else if (this.StudentsClass.grade == "初三年级") {
      //   grade = "9";
      // }
      let classes = "";
      if (this.StudentsClass.classes.length == 3) {
        classes = ` (${this.StudentsClass.classes.slice(0, 2)}) 班`;
      } else if (this.StudentsClass.classes.length == 2) {
        classes = ` (${this.StudentsClass.classes.slice(0, 1)}) 班`;
      }
      let course = this.StudentsClass.course;
      let userTitle = this.resultTableName
        ? this.resultTableName
        : this.StudentsClass.title;
      let examDate =
        this.StudentsClass.examDate.slice(0, 4) +
        this.StudentsClass.examDate.slice(5, 7) +
        this.StudentsClass.examDate.slice(8, 10);
      let examType = this.StudentsClass.examType;
      return `${school}${grade}${classes}-${course}${examType}${userTitle} ( ${examDate} ) 成绩单`;
    },
  },
};
</script>
<style lang="scss" scoped>
.addreport {
  background-color: #f9f9f9;
  .addreportTop {
    width: 100%;
    height: 120px;
    background-color: #fff;
    overflow: hidden;
    .steps {
      width: 500px;
      padding-top: 50px;
      margin: 0 auto;
      background-color: #fff;
      display: flex;
      justify-content: space-around;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #838f99;

      .line {
        height: 0.5px;
        width: 38%;
        background-color: #eb6123;
        margin-top: 16px;
        opacity: 0.5;
      }
      div {
        .stepsNum {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-color: #838f99;
          line-height: 24px;
          text-align: center;
          border-radius: 24px;
          font-size: 16px;
          font-family: Helvetica;
          color: #ffffff;
          vertical-align: middle;
          transform: translateY(-2px);
        }
      }
    }
    ::v-deep.steps {
      .active {
        color: #eb6123;

        .stepsNum {
          background-color: #eb6123 !important;
        }
      }
    }
  }
  .addreportContent {
    width: 100%;
    height: calc(100vh - 200px);
    min-height: 700px;
    position: relative;
    .contentBox {
      width: 1084px;
      height: 612px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: #fff;
      border-radius: 16px;
      .bot {
        width: 700px;
        height: 56px;
        position: absolute;
        left: 50%;
        bottom: 38px;
        margin-left: -350px;
        .el-button {
          display: block;
          height: 100%;
          width: 352px;
          border-radius: 28px;
          border: 1px solid #eb6123;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #eb6123;
          margin: 0 auto;
          &:focus {
            background: unset;
          }
          &:hover {
            border: 0;
            box-shadow: 0px 0px 5px 3px rgba($color: #eb6123, $alpha: 0.2);
            background: unset;
          }
        }
      }
      .contentBot2 {
        display: flex;
        justify-content: space-between;
        .el-button {
          display: inline-block;
          width: 223px;
          height: 56px;
          border-radius: 27px;
        }

        .saveButton:hover {
          background: unset;
        }
      }
      ::v-deep.contents {
        .contents1,
        .contents3 {
          text-align: left;
          .el-tabs {
            margin-top: 24px;
            .el-tabs__header {
              margin-bottom: 20px;
              height: 80px;
              .el-tabs__nav-wrap {
                .el-tabs__nav-scroll {
                  .el-tabs__nav {
                    margin-left: 340px;
                    .el-tabs__active-bar {
                      display: none;
                    }
                    .el-tabs__item {
                      height: 70px;
                      margin-right: 80px;
                      .el-radio__label {
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.85);
                      }
                      .el-radio__inner {
                        width: 24px;
                        height: 24px;
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.85);
                        background: #fff;
                        border-color: #eb6123;
                        vertical-align: middle;
                        transform: translateY(-3px);
                      }
                      .el-radio__input.is-checked .el-radio__inner {
                        border-color: #eb6123;
                        background: #fff;
                      }
                      .el-radio__inner::after {
                        width: 12px;
                        height: 12px;
                        background: #eb6123;
                      }
                    }
                  }
                }
                &::after {
                  display: none;
                }
              }
            }
            #tab-1 {
              & > div {
                position: relative;
                .prompt {
                  position: absolute;
                  left: -20px;
                  bottom: -30px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #838f99;
                }
              }
            }
          }
          .tableName {
            margin-top: 18px;
            padding-left: 67px;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #eb6123;

            i {
              width: 20px;
              height: 20px;
              transform: scale3d(1.8, 1.2, 1);
              margin: 0 10px;
              text-shadow: 2px 0 3px #eb6123;
            }
            .el-input {
              display: inline-block;
              width: 300px;
              font-size: 16px;
            }
          }
        }
        .contents2 {
          .el-tabs {
            margin-top: 24px;
            .el-tabs__header {
              margin-bottom: 0;
              height: 50px;
              .el-tabs__nav-wrap {
                .el-tabs__nav-scroll {
                  .el-tabs__nav {
                    margin-left: 340px;
                    .el-tabs__active-bar {
                      display: none;
                    }
                    .el-tabs__item {
                      height: 50px;
                      margin-right: 80px;
                      .el-radio__label {
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.85);
                      }
                      .el-radio__inner {
                        width: 24px;
                        height: 24px;
                        font-size: 22px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.85);
                        background: #fff;
                        border-color: #eb6123;
                        vertical-align: middle;
                        transform: translateY(-3px);
                      }
                      .el-radio__input.is-checked .el-radio__inner {
                        border-color: #eb6123;
                        background: #fff;
                      }
                      .el-radio__inner::after {
                        width: 12px;
                        height: 12px;
                        background: #eb6123;
                      }
                    }
                  }
                }
                &::after {
                  display: none;
                }
              }
            }
            #tab-1 {
              & > div {
                position: relative;
                .prompt {
                  position: absolute;
                  left: -20px;
                  bottom: -30px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #838f99;
                }
              }
            }
          }
          .tableName {
            margin-bottom: 32px;
            text-align: left;
            margin-top: 18px;
            padding-left: 67px;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #eb6123;
            i {
              width: 20px;
              height: 20px;
              transform: scale3d(1.8, 1.2, 1);
              margin: 0 10px;
            }
            .el-input {
              display: inline-block;
              width: 300px;
              font-size: 16px;
            }
          }
        }
        .el-upload {
          display: block;
          margin: 0 auto;

          .importBut {
            display: block;
            margin: 58px auto 15px;
            width: 352px;
            height: 56px;
            background: #eb6123;
            box-shadow: 0px 8px 16px 0px rgba(59, 196, 146, 0.15);
            border-radius: 28px;
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
        }
        .getTemplate {
          text-align: center;

          a {
            margin: 0 auto;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff0000;
          }
        }
        .el-upload-list {
          width: 300px;
          margin: 0 auto;
          text-align: center;
          a,
          i {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #eb6123;
          }
        }
        .el-form {
          padding: 0 23px;
          .el-form-item {
            width: 336px;
            height: 45px;
            line-height: 45px;
            margin: 0 5px 22px;
            .el-form-item__label {
              width: 90px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #6d7278;
              .fl {
                margin-left: 3px;
              }
              &::before {
                display: none;
              }
            }
            .el-input {
              width: 232px;
              height: 45px;
              input {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                border: 1px solid #eb6123;
                cursor: pointer;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #838f99;
              }
              .el-input__prefix {
                left: unset;
                right: 6px;
                font-size: 20px;
              }
              .el-icon-arrow-up {
                color: #eb6123;
                font-weight: 700;
              }
            }
            .el-date-editor {
              input {
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.el-select-dropdown__item {
  &:hover {
    background: rgba($color: #eb6123, $alpha: 0.1) !important;
  }
}
.el-select-dropdown__item.selected {
  color: #eb6123;
  &:hover {
    background: rgba($color: #eb6123, $alpha: 0.1);
  }
}
</style>
